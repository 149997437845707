span.twitter-typeahead {
  width: 100%;

  .tt-menu {
    @extend .dropdown-menu;

    width: 100%;
  }

  .tt-menu {
    width: 100%;
  }

  .tt-dropdown-menu {
    @extend .dropdown-menu;
  }

  .tt-suggestion {
    @extend .dropdown-item;
  }

  .tt-suggestion.tt-cursor {
    @extend .dropdown-item.active;
  }

  .input-group & {
    display: block !important;
    .tt-dropdown-menu {
      top:32px !important;
    }
  }
  .input-group.input-group-lg & {
    .tt-dropdown-menu {
      top:44px !important;
    }
  }
  .input-group.input-group-sm & {
    .tt-dropdown-menu {
      top:28px !important;
    }
  }
}
