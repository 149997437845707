#toast-container {
  & > div {
    @include box-shadow(0 0 3px #888);
    @include opacity(.92);

    &:hover {
      @include opacity(1);

      box-shadow: 0 0 4px #888;
    }
  }

  & > .toast {
    background-image: none !important;
  }

  & > .toast:before {
    font-family: FontAwesome;
    font-size: 24px;
    line-height: 24px;
    position: fixed;
    margin: auto .5em auto -1.5em;
    color: #fff;
  }

  & > .toast-warning:before {
    content: '\f075';
  }
  & > .toast-error:before {
    content: '\f071';
  }
  & > .toast-info:before {
    content: '\f1cd';
  }
  & > .toast-success:before {
    content: '\f00C';
  }

  & > .toast-error {
    background-color: darken(theme-color('danger'), 5%);
  }

  & > .toast-warning {
    background-color: darken(theme-color('warning'), 5%);
  }

  & > .toast-success {
    background-color: darken(theme-color('success'), 5%);
  }

  & > .toast-info {
    background-color: darken(theme-color('info'), 5%);
  }
}
